import React from 'react'
import PropTypes from 'prop-types'
import { LayoutContainer } from './styles'
import useMenu from '../../../../hooks/useMenu'
import useWindowSize from '../../../../hooks/useWindowSize'
import Sidebar from '../../components/SideBar/SideBar'

export const ThemeDefault = ({ children }) => {
  const { data } = useMenu()
  const windowsSize = useWindowSize()

  return (
    <LayoutContainer isNew={true} width={windowsSize?.width} className="layout theme-default tw-h-screen tw-overflow-hidden tw-relative tw-flex tw-flex-col tw-bg-gray-50">
      <div className='sm:tw-block tw-hidden'>
      <Sidebar menuItems={Array.isArray(data) ? data : []} />
      </div>
      <div className="tw-h-full tw-w-full">{children}</div>
    </LayoutContainer>
  )
}

ThemeDefault.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}
