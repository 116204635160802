import React from 'react'
import { Routes, BrowserRouter as Router, Route, Navigate } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './infrastructure/redux/store'
import { Provider } from 'react-redux'
import ThemeProvider from './infrastructure/context/ThemeProvider'
import NoAuth from './modules/Login/pages/NoAuth'
import AuthScreen from './modules/Auth/pages/AuthScreen'
import { ProtectedRoutes } from './infrastructure/routes'
import ScreenNavigator from './infrastructure/components/ScreenNavigator'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/login' element={<Navigate to="/" replace />} />
      <Route path='/no-auth' element={<NoAuth />} />
      <Route path='/auth/:code' element={<AuthScreen />} />

      <Route element={<ProtectedRoutes />}>
          <Route path="/">
            <Route index element={<ScreenNavigator />} />
            <Route path=":slug" element={<ScreenNavigator />} />
          </Route>
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

function App () {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ThemeProvider>
            <AppRoutes />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App
