import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Layout from '../components/Layout'
import TopBarManagement from '../../components/TopBarManagement'

const ProtectedRoutes = () => {
  const { user } = useSelector(state => state.userReducer)

  useEffect(() => {
    if (!user) {
      console.log('Redirecting...')
      window.location.href = 'https://accounts.farmacloud.io/sso/bi-prod?login=farmacloud'
    }
  }, [user])

  return (
    <>
      <TopBarManagement />
      <Layout>
        <Outlet/>
      </Layout>
    </>
  )
}

export default ProtectedRoutes
