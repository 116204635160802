import {
  Paragraphs,
  Spinner
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { actionSetTokenUser, actionUpdateUser } from '../../../../infrastructure/redux/actions/user'
import { userLogin } from '../../../../infrastructure/services/auth'

const AuthScreen = () => {
  const { code } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (code) {
      const formattedCode = code.split('&')[0]
      handleLogin({ code: formattedCode })
    }
  }, [code])

  async function handleLogin ({ code }) {
    const { status, user } = await userLogin({ code })
    if (status === 200) {
      dispatch(actionSetTokenUser({ token: code }))
      dispatch(actionUpdateUser(user))
      navigate('/')
      console.log('user', user)
    }
  }

  return (
    <div className="auth-screen tw-h-screen">
      <div className="tw-flex tw-h-full tw-items-center tw-justify-center tw-flex-col">
        <div>
          <Spinner color={'#333'} />
        </div>
        <div className="tw-text-center tw-mt-4">
          <Paragraphs>Autologin</Paragraphs>
          <Paragraphs>Comprobando credenciales</Paragraphs>
        </div>
      </div>
    </div>
  )
}

AuthScreen.propTypes = {}

export default AuthScreen
