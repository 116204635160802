import axios from 'axios'
import { URL_BASE_AUTH } from '../constants/configurations'

export async function userLogin ({ code }) {
  try {
    const { data } = await axios(
      `${URL_BASE_AUTH}/usuario`,
      {
        headers: {
          Authorization: `Bearer ${code}`
        }
      }
    )

    return {
      user: {
        ...data?.data
      },
      status: 200
    }
  } catch (error) {
    console.log('error', error)
    if (axios.isAxiosError(error)) {
      return { status: error?.response?.status }
    }
    return { status: 500 }
  }
}
