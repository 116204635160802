import React from 'react'
import PropTypes from 'prop-types'
import BisualFarmaScreen from '../../../modules/BI/pages/BisualFarmaScreen'
import useMenu from '../../hooks/useMenu'

const ScreenNavigator = () => {
  const { showMenuId } = useMenu()
  return <div
    data-id="stack-navigator"
    className="stack-navigator tw-w-full tw-h-full"
  >
    <BisualFarmaScreen key={showMenuId?.id} id={showMenuId?.id} />
  </div>
}

ScreenNavigator.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default ScreenNavigator
