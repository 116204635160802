import React from 'react'
import './index.css' // Asegúrate de importar el CSS

// eslint-disable-next-line react/prop-types
export const SidebarIcon = ({ iconName, fill = 0, weight = 200, grade = 0, opticalSize = 24, color }) => {
  return (
    <span
      className="material-symbols-rounded"
      style={{
        fontVariationSettings: `'FILL' ${fill}, 'wght' ${weight}, 'GRAD' ${grade}, 'opsz' ${opticalSize}`,
        fontSize: `${opticalSize}px`,
        color: color
      }}
    >
      {iconName}
    </span>
  )
}
