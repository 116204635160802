import { useDispatch, useSelector } from 'react-redux'
import { USUER } from '../../constants/api'
import { actionLogoutUser, actionUpdateUser } from '../../redux/actions/user'
import useMutation from '../useMutation'

export const useUser = () => {
  const [handleGetUser] = useMutation(USUER, false)
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.userReducer)
  /**
   * get info user
   *
   *
   * @author   ehernandez
   * @param    {Boolean}   updateStorage   update redux storage

   */
  const handleUser = async (updateStorage = true) => {
    const { success, data } = await handleGetUser({ method: 'get' })
    if (!success) {
      return null
    }
    if (updateStorage) {
      dispatch(actionUpdateUser(data))
    }
  }

  /**
   * Logout user event
   * remove token and redirect to login
   *
   *
   * @author   ehernandez
   * @param    {Boolean}   updateStorage   update redux storage

   */
  const logoutUser = () => {
    dispatch(actionLogoutUser())
  }

  return { handleUser, logoutUser, token }
}
