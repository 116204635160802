import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import './styles.css'

export function BTooltip ({
  id,
  title,
  className = '',
  disabled = false,
  children,
  place = 'left',
  openOnClick = false,
  clickable = false
}) {
  return (
    <>
      {/* El wrapper para el contenido que dispara el tooltip */}
      <div data-tip data-for={id} className="tw-inline-block">
        {children}
      </div>

      {/* Tooltip asociado al ID */}
      <ReactTooltip
        disable={disabled}
        id={id}
        effect="solid"
        place={place}
        clickable={clickable}
        backgroundColor='#1A090D'
        tooltipRadius='0'
        globalEventOff={openOnClick ? 'click' : null}
        className={`z-20 flex flex-col custom-tooltip custom-tooltip-radius gap-4 ${className}`}
      >
        {title && (
          <div className="">
            <p className="tw-text-sm tw-font-normal">{title}</p>
          </div>
        )}
      </ReactTooltip>
    </>
  )
}

BTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  place: PropTypes.string,
  openOnClick: PropTypes.bool,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool
}
