import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BTooltip } from '../BTooltip/BTooltip'
import './style.css'
import { SidebarIcon } from '../SidebarIcon/SideBarIcon'
import { useNavigate } from 'react-router-dom'
import useMenu from '../../../../hooks/useMenu'

const Sidebar = ({ menuItems }) => {
  const [hoveredItemId, setHoveredItemId] = useState(null)
  const { showMenuId } = useMenu()
  const [itemActive, setItemActive] = useState(null)
  const navigate = useNavigate()

  // Ordenar los items y sus children antes de renderizar
  const sortedMenuItems = menuItems
    .map(item => ({
      ...item,
      children: item.children?.sort((a, b) => a.orden - b.orden) || [] // Ordenar los children por "orden"
    }))
    .sort((a, b) => a.orden - b.orden) // Ordenar el nivel superior por "orden"

  const handleMouseEnter = (id) => {
    setHoveredItemId(id)
  }

  const handleMouseLeave = () => {
    setHoveredItemId(null)
  }
  const handleClick = (item, isChild = false, event) => {
    if (event) event.stopPropagation()
    let targetItem = item

    if (!isChild && item.children?.length > 0) {
      // Si es un padre con más de un hijo, mostrar el popup y cargar el primer hijo
      targetItem = item.children[0]
    }
    // si es un hijo el elemento activo es el padre
    if (isChild) {
      setItemActive(sortedMenuItems.find(i => i.id === item.padre_id))
    } else {
      setItemActive(item)
    }

    // Navegar al componente del targetItem seleccionado
    if (targetItem.componente) return navigate(targetItem.accion)
  }

  useEffect(() => {
    if (!itemActive && sortedMenuItems.length > 0) {
      if (showMenuId) {
        setItemActive(sortedMenuItems.find(i => i.id === showMenuId?.modulo_id))
      } else {
        const firstItem = sortedMenuItems[0]
        if (firstItem) {
          setItemActive(firstItem)
        }
      }
    }
  }, [sortedMenuItems, showMenuId])

  useEffect(() => {
    setItemActive(sortedMenuItems.find(i => i.id === showMenuId?.modulo_id))
  }, [showMenuId])

  return (
    <aside
      style={{
        width: '48px',
        zIndex: 99,
        paddingTop: '48px',
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        borderRight: '0.5px solid #EEEEF0',
        boxSizing: 'border-box'
      }}
      className="tw-top-0 tw-h-full tw-left-0 tw-flex tw-flex-col tw-items-center tw-fixed"
    >
      {sortedMenuItems?.map((item) => (
        <div

          onClick={() => handleClick(item)}
          key={item?.id}
          className='tw-relative menu-items-hover tw-h-16 tw-w-full tw-flex tw-items-center tw-justify-center '
          style={{
            borderRight: itemActive?.id === item?.id ? '2px solid #761A80' : '2px solid transparent',
            borderLeft: '2px solid transparent'
          }}
          onMouseEnter={() => {
            handleMouseEnter(item.id)
          }}
          onMouseLeave={handleMouseLeave}
        >
              <BTooltip disabled={item.children.length > 1} id={item?.nombre} title={item.nombre} place="right">

          {item?.img && <div
            className="tw-flex tw-flex-col tw-cursor-pointer tw-items-center tw-justify-center tw-p-3 "
          >
            <SidebarIcon
              color = {itemActive?.id === item?.id ? '#761A80' : '#000000'}
              weight = {itemActive?.id === item?.id || hoveredItemId === item.id ? 400 : 200}
              iconName= {item?.img}
            />
          </div>}

          {/* Popup de los children (controlado por estado) */}
          {item?.children?.length > 1 && hoveredItemId === item.id && (
            <div className=' tw-absolute tw-min-w-32 tw-left-full tw-top-0 tw-transform -tw-translate-y-1/2 tw-bg-transparent tw-rounded-lg tw-px-3   tw-flex tw-flex-col'>
            <div
            style={{
              minWidth: 'max-content'
            }}
              className="  tw-bg-white tw-flex tw-flex-col   tw-rounded-md tw-shadow-md">
              <div className=' tw-pt-3 tw-pb-2 tw-flex tw-flex-col'>
              {item?.children?.map((subItem) => (
                <p
                onClick={(event) => handleClick(subItem, true, event)}
                  key={subItem.id}
                  style={{
                    color: '#1A090D'
                  }}
                  className="sub-item tw-px-4  tw-py-1.5 tw-w-full tw-text-sm  tw-cursor-pointer"
                >
                  {subItem?.nombre}
                </p>
              ))}
              </div>
                <div
                style={{
                  backgroundColor: '#FAFAFA'
                }}
                id='icon-father' className='tw-h-10 tw-rounded-b-md tw-px-4 tw-flex tw-items-center tw-gap-1'>
                <SidebarIcon
                 opticalSize={16}

                iconName= {item.img}
            />
              <span className='tw-text-xs tw-mt-1'>{item.nombre}</span>
            </div>
            </div>
            </div>
          )}
          </BTooltip>
        </div>
      ))}
    </aside>
  )
}

Sidebar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      componente: PropTypes.string,
      accion: PropTypes.string,
      img: PropTypes.string.isRequired,
      padre_id: PropTypes.number.isRequired,
      software_id: PropTypes.number.isRequired,
      orden: PropTypes.number.isRequired,
      visible: PropTypes.number.isRequired,
      modulo_id: PropTypes.number,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          nombre: PropTypes.string.isRequired,
          tipo: PropTypes.string.isRequired,
          componente: PropTypes.string,
          accion: PropTypes.string,
          img: PropTypes.string,
          padre_id: PropTypes.number.isRequired,
          software_id: PropTypes.number.isRequired,
          orden: PropTypes.number.isRequired,
          visible: PropTypes.number.isRequired,
          modulo_id: PropTypes.number
        })
      ).isRequired
    })
  ).isRequired
}

export default Sidebar
