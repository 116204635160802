import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useUser from '../../infrastructure/hooks/useUser'
import { useSelector } from 'react-redux'

const TopBarManagement = ({ applicationTopbarId, applicationSidebarClassName, applicationSidebarPaddingTop, applicationSidebarBackgroundColor }) => {
  const [farmacloudBar] = useState(document.getElementById('farmacloud-header-bar'))
  const { logoutUser } = useUser()
  const { user } = useSelector(state => state.userReducer)

  /* Getting the token from the backend and setting it to the farmacloudBar. */
  useEffect(async () => {
    if (user?.farmacloud_token) {
      farmacloudBar?.setToken({ token: user?.farmacloud_token })
    } else {
      window.location.href = 'https://accounts.farmacloud.io/sso/bi-prod?login=farmacloud'
    }
  }, [user])

  /* Adding an event listener to the farmacloudBar to move the sidebar down. */
  useEffect(() => {
    farmacloudBar.addEventListener('onShowBar', (e) => {
      const sidebar = document.getElementsByClassName(applicationSidebarClassName)
      if (sidebar[0]) {
        sidebar[0].style.paddingTop = applicationSidebarPaddingTop
        sidebar[0].style.backgroundColor = applicationSidebarBackgroundColor
      }
      const sideBarSfera = document.getElementsByClassName('Sidebar-small')
      sideBarSfera[0].style.width = '49px'
    })
    return farmacloudBar.removeEventListener('onShowBar', (e) => { return null })
  }, [])

  /* Adding an event listener to the farmacloudBar to make logout. */
  useEffect(() => {
    const doLogout = () => {
      logoutUser()
    }

    farmacloudBar.addEventListener('logged-out', doLogout)
    farmacloudBar?.addEventListener('switch-user', (e) => {
      console.log('switch-user', e)
      doLogout()
    })

    return () => {
      farmacloudBar.removeEventListener('logged-out', (e) => {
        return null
      })
      farmacloudBar.removeEventListener('switch-user', null)
    }
  }, [])

  return (
    <div className={'TopBarManagement relative'}>
      {/* TopBarManagement */}
    </div>
  )
}

TopBarManagement.propTypes = {
  applicationTopbarId: PropTypes.string.isRequired,
  applicationSidebarClassName: PropTypes.string.isRequired,
  applicationSidebarPaddingTop: PropTypes.string.isRequired,
  applicationSidebarBackgroundColor: PropTypes.string.isRequired
}

TopBarManagement.defaultProps = {
  applicationTopbarId: 'bi-topbar',
  applicationSidebarClassName: 'Sidebar',
  applicationSidebarPaddingTop: '48px',
  applicationSidebarBackgroundColor: '#000000'
}

export default TopBarManagement
