import React from 'react'
import PropTypes from 'prop-types'
import { ThemeDefault } from './themes/ThemeDefault/ThemeDefault'

const Layout = ({ children }) => {
  return (
    <>
      <ThemeDefault>
        {children}
      </ThemeDefault>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Layout
