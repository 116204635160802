import React from 'react'

const NoAuth = () => {
  return (
    <div className='tw-flex tw-h-screen tw-items-center tw-justify-center tw-bg-gray-100'>
      <div className='tw-text-center'>
        <h1 className='tw-text-3xl tw-font-bold tw-text-red-600'>Acceso Denegado</h1>
        <p className='tw-mt-4 tw-text-lg tw-text-gray-700'>
          No tienes permisos para acceder a esta página.
        </p>
      </div>
    </div>
  )
}

export default NoAuth
