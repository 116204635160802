import styled from 'styled-components'

export const LayoutContainer = styled.div`
    margin-left: ${props => props.width >= 640 ? '49px' : '0'};
    padding-top: 48px;
    & .Sidebar-small .BtnModule img{
        width: 20px;
        height: 20px;
    }
`
