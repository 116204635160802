import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RECURSOS } from '../../constants/api'
import useMutation from '../useMutation'
import { useNavigate, useParams } from 'react-router-dom'

export const useMenu = () => {
  const [data, setData] = useState(null)
  const [showMenuId, setShowMenuId] = useState(null)
  const [getMenus] = useMutation(RECURSOS)
  const { user } = useSelector(state => state.userReducer)
  const { slug } = useParams()
  const navigate = useNavigate()

  const findMenuItemIdByAccion = (slug, menuItems) => {
    if (!menuItems && !menuItems?.length) return
    for (const item of menuItems) {
      if (item.componente === 'bi_iframe' && item.accion === slug) {
        return item
      }
      if (item.children && item.children.length > 0) {
        const found = findMenuItemIdByAccion(slug, item.children)
        if (found !== null) return found
      }
    }
    return null
  }

  const findFirstValidMenuItemId = (menuItems) => {
    if (!menuItems || !menuItems.length) return null
    for (const item of menuItems) {
      if (item.componente === 'bi_iframe' && item.accion) {
        return item
      }
      if (item.children && item.children.length > 0) {
        const found = findFirstValidMenuItemId(item.children)
        if (found !== null) return found
      }
    }
    return null
  }

  /**
   * get Menu items
   *
   *
   * @author   ehernandez
   */

  const getMenuList = async () => {
    const { success, data: menu } = await getMenus({ method: 'get' })
    let newData = menu
    if (success) {
      if (user?.username?.includes('_AUX')) {
        const only = [1609, 1612, 1613, 1615, 1601, 1610, 1611, 1618, 1622, 1620, 1624]

        const newMenu = menu.filter(item => {
          if (only.includes(item.id)) {
            item.children = item.children.filter(child => {
              if (only.includes(child.id)) {
                return child
              }
              return null
            })
            return item
          }
          return null
        })
        newData = newMenu
      }

      // order array object for key order
      newData = newData.sort((a, b) => {
        if (a.orden > b.orden) {
          return 1
        }
        if (a.orden < b.orden) {
          return -1
        }
        return 0
      }
      )
      newData = newData.map(i => {
        i.children.sort(function (a, b) {
          if (a.orden > b.orden) {
            return 1
          }
          if (a.orden < b.orden) {
            return -1
          }
          return 0
        })
        return i
      })
      setData(newData)
    }
  }
  /**
   * initialice funtion getmenus
   *
   *
   * @author   ehernandez
   * @param   {Object} item
   */

  useEffect(() => {
    if (user) {
      getMenuList()
    }
  }, [user])

  useEffect(() => {
    if (data?.length) {
      if (slug) {
        const menuFound = findMenuItemIdByAccion(slug, data)
        if (menuFound) {
          setShowMenuId(menuFound)
        } else {
          navigate('/')
        }
      } else {
        const validMenuFound = findFirstValidMenuItemId(data)
        setShowMenuId(validMenuFound)
      }
    }
  }, [data, slug])

  return { data, showMenuId }
}
