import { useEffect, useState } from 'react'
import { BI_MENU } from '../../../../infrastructure/constants/api'
import useMutation from '../../../../infrastructure/hooks/useMutation'

export const usePorwerBI = (id) => {
  const [getMenuBi] = useMutation(BI_MENU + id)
  const [BI, setBI] = useState(null)
  const [loading, setLoading] = useState(false)
  /**
   * get token and data from microsoft
   *
   *
   * @author   ehernandez
   * @param   {Object} item
   */
  const getToken = async () => {
    setLoading(true)
    const { success, data } = await getMenuBi({ method: 'get' })
    const response = data
    setLoading(false)

    if (success) {
      // Sobrescribir el valor de store_code en todos los filtros para pruebas
      // response.filtrosBI = response.filtrosBI.map((filtro) => ({
      //   ...filtro,
      //   value:
      // }))
      setBI(response)
    }

    return null
  }

  useEffect(() => {
    if (id) getToken()
  }, [id])

  return { getToken, BI, loading }
}
